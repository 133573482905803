<template>
    <div
        class="room-design-ideas-page"
        v-if="Object.keys(roomDesignIdeas.payload).length > 0"
    >
        <BreadCrumbs :current="roomDesignIdeas.payload.name + ' Design'" />
        <DisplayBanner img="Bedroom_design.png">
            <div class="wrapper">
                <Hexagon
                    :t1="`${roomDesignIdeas.payload.name.toUpperCase()} DESIGN`"
                />
            </div>
        </DisplayBanner>

        <div class="content-container">
            <div class="room-description">
                <div class="social-sharing">
                    <p>Share on</p>
                    <div class="networks">
                        <ShareNetwork
                            network="facebook"
                            :url="currentUrl"
                            :title="
                                `${roomDesignIdeas.payload.name.toUpperCase()} DESGIN`
                            "
                            :description="roomDesignIdeas.payload.description"
                            hashtags="interior, designer"
                            :quote="roomDesignIdeas.payload.description"
                        >
                            <span>
                                <font-awesome-icon
                                    :icon="['fab', 'facebook']"
                                />
                            </span>
                        </ShareNetwork>
                    </div>
                </div>
                <HeadAndSubTexts
                    :subHeading2="roomDesignIdeas.payload.description"
                />
            </div>

            <div
                class="featured-showcases-container"
                v-if="roomDesignIdeas.payload.featured.length > 0"
            >
                <HeadAndSubTexts
                    :heading="
                        `Featured ${roomDesignIdeas.payload.name} Showcases`
                    "
                />
                <div class="featured-showcases">
                    <div
                        v-for="featured in roomDesignIdeas.payload.featured"
                        :key="featured.id"
                    >
                        <a :href="featured.link" target="_blank">
                            <OverLayCard
                                alignment="center"
                                ovalOverlay
                                :title="featured.floorplan"
                                :description="featured.description"
                                :img="
                                    featured.image
                                        ? featured.image.n_image
                                        : require('@/assets/images/default-image1.png')
                                "
                            />
                        </a>
                    </div>
                </div>
            </div>

            <div
                class="room-design-ideas"
                v-if="roomDesignIdeas.payload.designs.length > 0"
            >
                <HeadAndSubTexts
                    :heading="`${roomDesignIdeas.payload.name} Design Ideas`"
                />

                <div v-if="windowWidth < 922">
                    <ImageGroup
                        :designs="designsArrayChunk[0]"
                        layoutType="5"
                        useBy="room"
                    />
                </div>
                <div v-else>
                    <ImageGroup
                        :designs="designsArrayChunk[0]"
                        layoutType="3"
                        useBy="room"
                    />
                </div>

                <StyleBox
                    class="style-quiz-banner"
                    leftBanner
                    img="styleQuizPhoto3.png"
                    heading="Unsure About Your Decor Style?"
                />

                <div v-if="windowWidth < 922">
                    <ImageGroup
                        :designs="designsArrayChunk[1]"
                        layoutType="5"
                        useBy="room"
                    />
                </div>
                <div v-else>
                    <ImageGroup
                        :designs="designsArrayChunk[1]"
                        layoutType="4"
                        useBy="room"
                    />
                </div>
            </div>

            <div
                class="room-products"
                v-if="roomDesignIdeas.payload.products.length > 0"
            >
                <HeadAndSubTexts
                    :heading="
                        ` Product that go well in ${roomDesignIdeas.payload.name}`
                    "
                />
                <Carousel :settings="settings">
                    <div
                        class="cards"
                        v-for="product in roomDesignIdeas.payload.products"
                        :key="product.id"
                    >
                        <router-link
                            :to="{
                                name: 'ProductDetailsPage',
                                params: { id: product.product_id },
                            }"
                        >
                            <Cards
                                class="card"
                                :cardname="product.name"
                                :img="product.image_meta.p_image"
                            />
                        </router-link>
                    </div>
                </Carousel>
            </div>

            <!-- <StyleBox
                class="style-quiz-banner"
                centerBanner
                img="styleQuizPhoto.png"
                heading="Unsure About your Style ?"
            /> -->
        </div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <Footer bannerType="center" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import RoomDesignIdeasPageModule from 'store/modules/RoomDesignIdeasPage'

import DisplayBanner from 'componentsv2/DisplayBanner'
import Hexagon from 'componentsv2/Hexagon'
import HeadAndSubTexts from 'componentsv2/HeadAndSubTexts'
import ImageGroup from 'componentsv2/ImageGroup'
import OverLayCard from 'componentsv2/OverLayCard'
import StyleBox from 'componentsv2/StyleBox'
import Carousel from 'componentsv2/Carousel'
import Cards from 'componentsv2/Cards'
import Loader from 'componentsv2/Loader'
import Footer from 'componentsv2/Footer'
import BreadCrumbs from 'componentsv2/BreadCrumbs'
import loaderHandler from 'mixins/loader'

export default {
    name: 'RoomDesignIdeasPage',
    components: {
        DisplayBanner,
        Hexagon,
        HeadAndSubTexts,
        ImageGroup,
        OverLayCard,
        StyleBox,
        Carousel,
        Cards,
        Loader,
        Footer,
        BreadCrumbs,
    },
    mixins: [RegisterStoreModule, loaderHandler],
    data() {
        return {
            currentUrl:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_BASE_URL_B2B
                    : process.env.VUE_APP_BASE_URL + this.$route.path.slice(1),
            settings: {
                arrows: true,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 3,
                touchThreshold: 5,
                responsive: [
                    {
                        breakpoint: 922,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true,
                        },
                    },
                ],
            },
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },

    computed: {
        ...mapState({
            roomDesignIdeas: state => state.RoomDesignIdeasPage.roomDesignIdeas,
            windowWidth: state => state.AppModule.windowWidth,
        }),

        designsArrayChunk() {
            return this.roomDesignIdeas.payload.designs.reduce(
                (resultArray, item, index) => {
                    const chunkIndex = Math.floor(index / 6)
                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = []
                    }
                    resultArray[chunkIndex].push(item)
                    return resultArray
                },
                [],
            )
        },
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'SILOHO',
        // all titles will be injected into this template
        titleTemplate: '%s | Room design ideas',
    },
    created() {
        document.querySelector('body').style.overflow = 'hidden'
        this.registerStoreModule(
            'RoomDesignIdeasPage',
            RoomDesignIdeasPageModule,
        )
        this.fetchRoomDesignIdeas(this.$route.params.id)
            .then(response => {
                if (response.responseCode == 200) {
                    document.querySelector(
                        "meta[property='og:title']",
                    ).content = `${this.roomDesignIdeas.payload.name} Design`

                    document.querySelector(
                        "meta[property='og:description']",
                    ).content = `${this.roomDesignIdeas.payload.description}`

                    setTimeout(() => {
                        document.querySelector('body').style.overflow = 'auto'
                    }, 100)
                }
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
                // else {
                //     this.$router.push({ name: '404' })
                // }
            })
            .catch(err => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            })
    },
    destroyed() {
        this.$store.unregisterModule('RoomDesignIdeasPage')
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        ...mapActions({
            fetchRoomDesignIdeas: 'fetchRoomDesignIdeas',
            onResize: 'onResize',
        }),
    },
}
</script>

<style lang="scss" scoped>
@import './RoomDesignIdeasPage.scss';
</style>
